import { useForm, SubmitHandler } from 'react-hook-form';
import axios from 'axios';
import useMetaTags from './metatags';
import { API_BASE_URL, API_KEY } from '../Constants/constants';
import { useState } from 'react';

interface IFormInput {
	firstname: string;
	surname: string;
	email: string;
	message: string;
}

function Contact() {
	useMetaTags(
		'Contact Us Today - Estate Agent Clacton | Blake &amp; Thickbroom',
		'Looking To Buy, Rent, Let Or Sell Property In Clacton, Essex? Contact Blake &amp; Thickbroom Today &amp; Discover How Our Team Can Support You!'
	);

	const [message, setMessage] = useState('');

  const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = async (data, e) => {
		e?.preventDefault(); // Prevent the default form submission

		try {
			await axios.post(`${API_BASE_URL}api/email/send`, data, {
				headers: {
					'x-api-key': API_KEY,
				},
			});

			// Update message and clear form fields
			setMessage('Email sent successfully.');
			e?.target.reset(); // Reset the form fields
		} catch (error) {
			console.error('Error sending email:', error);
			setMessage('Failed to send email.');
		}
	};

	return (
		<div>
			<section className="p-0">
				<iframe
					className="home8-map contact-page contactbg"
					loading="lazy"
					src=""
					title="Blake & Thickbroom"
					aria-label="Blake & Thickbroom"></iframe>
			</section>
			<section>
				<div
					id="mobile"
					className="container mt150">
					<div className="row d-flex align-items-end">
						<div className="col-lg-5 position-relative">
							<div className="home8-contact-form default-box-shadow1 bdrs12 bdr1 p30 mb30-md bgc-white">
								<h4 className="form-title mb25">
									Have questions? Get in touch!
								</h4>
								<form
									onSubmit={handleSubmit(onSubmit)}
									className="form-style1">
									<div className="row">
										<div className="col-lg-12">
											<div className="mb20">
												<label className="heading-color ff-heading fw600 mb10">
													First Name
												</label>
												<input
													{...register('firstname', { required: true })}
													className="form-control"
												/>
												{errors.firstname && (
													<span>This field is required</span>
												)}
											</div>
										</div>
										<div className="col-lg-12">
											<div className="mb20">
												<label className="heading-color ff-heading fw600 mb10">
													Last Name
												</label>
												<input
													{...register('surname', { required: true })}
													className="form-control"
												/>
												{errors.surname && <span>This field is required</span>}
											</div>
										</div>
										<div className="col-md-12">
											<div className="mb20">
												<label className="heading-color ff-heading fw600 mb10">
													Email
												</label>
												<input
													type="email"
													{...register('email', {
														required: 'This field is required',
														pattern: {
															value:
																/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
															message: 'Invalid email address',
														},
													})}
													className="form-control"
												/>
												{errors.email && <span>{errors.email.message}</span>}
											</div>
										</div>
										<div className="col-md-12">
											<div className="mb10">
												<label className="heading-color ff-heading fw600 mb10">
													Message
												</label>
												<textarea
													{...register('message', { required: true })}
													className="form-control"
													cols={30}
													rows={4}
												/>
												{errors.message && <span>This field is required</span>}
											</div>
										</div>
										<div className="col-md-12">
											<div className="d-grid">
												<button
													type="submit"
													className="ud-btn btn-thm">
													Submit <i className="fal fa-arrow-right-long"></i>
												</button>
											</div>
											{message && (
												<div
													className="alert alart_style_four fade show mb20"
													role="alert">
													{message}
												</div>
											)}
										</div>
									</div>
								</form>
							</div>
						</div>
						<div id="mobile" className="col-lg-5 offset-lg-2 mt100 contactxt">
							<h2 className="text-capitalize mb70">
								Please get in touch with any questions or enquiries you may have
								today.
								<br className="d-none d-lg-block" />
								Our friendly team will be happy to help.
							</h2>
							<p className="text mb50">
								We are here to answer any question you may have. As a partner of
								corporates, realton has more than 9,000 offices of all sizes and
								all potential of session.
							</p>
						</div>
					</div>
				</div>
			</section>

			<section className="pt0 pb90 pb10-md">
				<div className="container">
					<div className="row">
						<div
							className="col-lg-6 m-auto wow fadeInUp"
							data-wow-delay="100ms">
							<div className="main-title text-center">
								<h2 className="title">Visit Our Offices</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div
							className="col-sm-6 col-lg-6 wow fadeInLeft"
							data-wow-delay="00ms">
							<div className="iconbox-style8 text-center">
								<div>
									<img
										src="/images/about/clactonoffice.jpg"
										alt="Clacton Office"
										className="maxw420"
									/>
								</div>
								<div className="iconbox-content">
									<h4 className="title">Clacton-on-Sea</h4>
									<p className="text mb-1">
										70 Station Road, Clacton-on-Sea, Essex, CO15 1SP
									</p>
									<h6 className="mb10">
										Tel: <a href="tel:01255 688788">01255 688788</a> Fax: 01255
										688666
									</h6>
									<h6 className="mb10">
										<a href="mailto:enquiries@blake-thickbroom.co.uk">
											enquiries@blake-thickbroom.co.uk
										</a>
									</h6>
									<a
										className="text-decoration-underline"
										href="https://maps.google.com/maps?q=Blake+%26+Thickbroom%20Clacton-on-sea%20United%20Kingdom"
										target="_blank"
										rel="noreferrer">
										Open Google Map
									</a>
								</div>
							</div>
						</div>
						<div
							className="col-sm-6 col-lg-6 wow fadeInUp"
							data-wow-delay="100ms">
							<div className="iconbox-style8 active text-center">
								<div>
									<img
										src="/images/about/hollandoffice.jpg"
										alt="Holland Office"
										className="maxw420"
									/>
								</div>
								<div className="iconbox-content">
									<h4 className="title">Holland-on-Sea</h4>
									<p className="text mb-1">
										375 Holland Road, Holland-on-Sea, Essex, CO15 6PD
									</p>
									<h6 className="mb10">
										Tel: <a href="tel:01255 688788">01255 688788</a> Fax: 01255
										688666
									</h6>
									<h6 className="mb10">
										<a href="mailto:enquiries@blake-thickbroom.co.uk">
											enquiries@blake-thickbroom.co.uk
										</a>
									</h6>
									<a
										className="text-decoration-underline"
										href="https://maps.google.com/maps?q=Blake+%26+Thickbroom%20Holland-on-sea%20United%20Kingdom"
										target="_blank"
										rel="noreferrer">
										Open Google Map
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default Contact;
